import React, { Component } from 'react'
import { Layout } from 'core/components'
import { AAnimate, ABanner } from 'shared/components/common'
import { Container, Row, Col } from 'reactstrap'

type AvisoPrivacidadProps = PageProps
interface AvisoPrivacidadState {
  mounted: boolean
}
class AvisoPrivacidad extends Component<AvisoPrivacidadProps, AvisoPrivacidadState> {
  state = {
    mounted: false
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ mounted: true })
    }, 0)
  }

  render() {
    const {
      pageContext: { locale }
    } = this.props
    const { mounted } = this.state

    return (
      <Layout locale={locale}>
        <AAnimate type="fadeIn" in={mounted}>
          <React.Fragment>
            <ABanner title="Aviso de privacidad" titleClassName="big-white" titleSize={12} />
            <Container className="pad">
              <Row>
                <Col sm={12} className="text-justify">
                  <p>
                    El presente aviso de privacidad se emite en cumplimiento a lo establecido a Ley Federal de Protección de Datos
                    Personales en Posesión de los Particulares el cual aplicará para todos los servicios que preste y/o disponga ALL ABORDO:
                  </p>
                  <p>
                    AUTOTRANSPORTES Y CARGA ALL ABORDO SAPI DE CV, (ALL ABORDO), con domicilio en calle AV. ZARAGOZA 240 NTE. colonia
                    CENTRO, ciudad LOS MOCHIS, municipio o delegación AHOME, cp. 81200, en la entidad de SINALOA, país MEXICO, y portal de
                    internet https://www.allabordo.com es el responsable del uso y protección de sus datos personales, y al respecto le
                    informamos lo siguiente:
                  </p>
                  <h4 className="privacy-title">¿Para qué fines utilizaremos sus datos personales? </h4>
                  <p>
                    Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el
                    servicio que solicita:
                  </p>
                  <ul>
                    <li>Mercadotecnia o publicitaria</li>
                    <li>Prospección comercial</li>
                  </ul>
                  <p>
                    De manera adicional, utilizaremos su información personal para las siguientes finalidades secundarias que no son
                    necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atención:
                  </p>
                  <ul>
                    <li>PROMOCIONES</li>
                    <li>PUBLICIDAD</li>
                    <li>OFERTAS</li>
                  </ul>
                  <p>
                    En caso de que no desee que sus datos personales se utilicen para estos fines secundarios, indíquelo a continuación:
                  </p>
                  <p>
                    La negativa para el uso de sus datos personales para estas finalidades no podrá ser un motivo para que le neguemos los
                    servicios y productos que solicita o contrata con nosotros.
                  </p>
                  <h4 className="privacy-title">¿Qué datos personales utilizaremos para estos fines?</h4>
                  <p>
                    Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos
                    personales que usted nos proporcione voluntariamente:
                  </p>
                  <ul>
                    <li>Nombre</li>
                    <li>Correo electrónico</li>
                    <li>Correo electrónico institucional</li>
                    <li>Teléfono institucional</li>
                    <li>Teléfono móvil</li>
                    <li>Datos de identificación</li>
                    <li>Datos de contacto (domicilio)</li>
                    <li>INE</li>
                    <li>Cookies</li>
                    <li>Video Grabaciones</li>
                    <li>Edad</li>
                    <li>Cuentas de sus redes sociales</li>
                  </ul>
                  <p>
                    Cuando usted realice un pago con tarjeta de crédito o débito, le solicitaremos los datos financieros del titular los
                    cuales por ningún motivo serán transferidos a terceros.{' '}
                  </p>
                  <h4 className="privacy-title">¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso? </h4>
                  <p>
                    Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso
                    que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté
                    desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos
                    cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como oponerse al uso de sus
                    datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.
                  </p>
                  <p>
                    Para conocer el procedimiento y requisitos para el ejercicio de los derechos ARCO, ponemos a su disposición el siguiente
                    medio:
                  </p>
                  <p>https://www.allabordo.com/solicitud_derechos_arco.pdf</p>
                  <p>
                    Los datos de contacto de la persona o departamento de datos personales, que está a cargo de dar trámite a las
                    solicitudes de derechos ARCO, son los siguientes:{' '}
                  </p>
                  <p>
                    a) Nombre de la persona o departamento de datos personales: DEPARTAMENTO ADMINISTRATIVO/Nombre/Apellidos/Puesto/Teléfono
                    <br />
                    b) Domicilio: calle AV ZARAGOZA 240 NTE., colonia CENTRO, ciudad LOS MOCHIS, municipio o delegación AHOME, cp. 81200, en
                    la entidad de SINALOA, país MEXICO
                  </p>
                  <h4 className="privacy-title">Usted puede revocar su consentimiento para el uso de sus datos personales</h4>
                  <p>
                    Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales.
                    Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso
                    de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales.
                    Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento implicará que no le podamos
                    seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.
                  </p>
                  <p>Para revocar su consentimiento deberá presentar su solicitud a través del siguiente medio: </p>
                  <ul>
                    <li>
                      Nombre de la persona o departamento de datos personales: DEPARTAMENTO ADMINISTRATIVO/Nombre/Apellidos/Puesto/Teléfono
                    </li>
                    <li>
                      Domicilio: calle AV ZARAGOZA 240 NTE., colonia CENTRO, ciudad LOS MOCHIS, municipio o delegación AHOME, cp. 81200, en
                      la entidad de SINALOA, país MEXICO
                    </li>
                    <li>Vía correo electrónico: atencion@allabordo.com</li>
                  </ul>
                  <h3 className="privacy-title">
                    Para conocer el procedimiento y requisitos para la revocación del consentimiento, ponemos a su disposición el siguiente
                    medio:
                  </h3>
                  <p>Vía correo electrónico: atencion@allabordo.com</p>
                  <h4 className="privacy-title">¿Cómo puede limitar el uso o divulgación de su información personal?</h4>
                  <p>
                    Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le informamos que puede
                    contactarnos de manera telefónica, dando notificación al departamento encargado de sus datos personales, también puede
                    acudir de manera personal al domicilio de la empresa o enviando un correo electrónico al e-mail del departamento
                    correspondiente de la organización.
                  </p>
                  <h4 className="privacy-title">Seguridad de la Información</h4>
                  <p>
                    Le hacemos de su conocimiento que la organización como empresa responsable del tratamiento de sus datos personales
                    cuenta con procesos administrativos y electrónicos sumamente eficaces para proteger la información personal contra
                    pérdidas, destrucción, alteraciones ilegales y/o accesos no autorizados a la misma.
                  </p>
                  <h4 className="privacy-title">¿Cómo puede conocer los cambios en este aviso de privacidad?</h4>
                  <p>
                    El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos
                    requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras
                    prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.
                  </p>
                  <p>
                    Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de
                    cualquier de los siguientes medios:{' '}
                  </p>
                  <ul>
                    <li>Página web</li>
                    <li>Correo electrónico</li>
                    <li>Llamada telefónica</li>
                    <li>Mensaje de texto</li>
                  </ul>
                  <p>
                    Cualquier modificación al aviso de privacidad se volverá efectiva inmediatamente al ser publicada en nuestra página de
                    internet www.allabordo.com, una vez publicadas las modificaciones se asumirá que el titular que continúe usando nuestra
                    página web tendrá el conocimiento, habrá leído y consentido el aviso de privacidad modificado.
                  </p>
                  <h4 className="privacy-title">Su consentimiento para el tratamiento de sus datos personales </h4>
                  <p>
                    El usuario consiente que sus datos personales sean tratados de conformidad con los términos y condiciones informados en
                    el presente aviso de privacidad.{' '}
                  </p>
                </Col>
              </Row>
            </Container>
          </React.Fragment>
        </AAnimate>
      </Layout>
    )
  }
}

export default AvisoPrivacidad
